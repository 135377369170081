<template>
  <div class="popup-add-something">
    <div class="popup-add-something__wrapper">
      <Column align="center"
              justify="center">
        <p class="popup-add-something__title">Что хотите добавить?</p>
        <Row class="popup-add-something__buttons"
             :justify="$viewport.desktop ? 'around' : 'start'"
             :wrap="$viewport.mobile">
          <Column align="center"
                  v-on:click.native="$openPopupAddObject"
                  class="popup-add-something__button">
            <div class="popup-add-something__icon-holder">
              <Icon viewport="0 0 23 30"
                    xlink="object"
                    class="popup-add-something__icon"/>
            </div>
            <span class="popup-add-something__icon-title">Объект</span>
          </Column>
          <Column align="center"
                  v-on:click.native="$openPopupAddTrip"
                  class="popup-add-something__button">
            <div class="popup-add-something__icon-holder">
              <Icon viewport="0 0 23 30"
                    xlink="object"
                    class="popup-add-something__icon"/>
            </div>
            <span class="popup-add-something__icon-title">Маршрут</span>
          </Column>
          <Column align="center"
                  v-on:click.native="$openPopupAddExcursion"
                  class="popup-add-something__button">
            <div class="popup-add-something__icon-holder">
              <Icon viewport="0 0 23 30"
                    xlink="excursion"
                    class="popup-add-something__icon"/>
            </div>
            <span class="popup-add-something__icon-title">Экскурсия</span>
          </Column>
          <Column align="center"
                  v-on:click.native="$openPopupAddFact"
                  class="popup-add-something__button">
            <div class="popup-add-something__icon-holder">
              <Icon viewport="0 0 34 26"
                    xlink="fact"
                    class="popup-add-something__icon"/>
            </div>
            <span class="popup-add-something__icon-title">Факт</span>
          </Column>
        </Row>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupAddSomething',
};
</script>
